/* extra-small screens -------------------------------------- */

@media
  only screen and (max-width: 609px) {
  .section { width: 280px; }
  header.section { margin: 50px auto; }
  .logo { 
    float: none;
    margin: 0 0 50px 0; 
  }
  .menu.specialties,
  .menu.secondary {
    display: block;
    ul { 
      display: block; 
      height: auto;
    }
    li { 
      display: block;
      margin-bottom: 10px;
      font-size: 13px;
    }
  }
}
@media
  only screen and (min-width: 710px) {
  .section { width: 610px; }
}
@media
  only screen and (min-width: 1040px) {
  .section { width: 940px; }
}
@media
  only screen and (min-width: 1370px) {
  .section { width: 1270px; }
}