@import "init";
// @import "compass/css3";
// @import "compass/support";

body { 
  font-size: 62.5%;
}

/* improve admin menu drop down usability */
#admin-menu .dropdown a, #admin-menu .dropdown li > span { padding: 8px; }
body.admin-menu { margin-top: 28px !important; }

$black: #333;
$grey: #bbb;
// $highlight: #55ADEC;
$highlight: #84C3F0;

@mixin outline {
  display: inline-block;
  outline: 1px solid $highlight;
  outline-offset: 5px;
}