/* RESET */

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, 
pre, a, address, cite, code, em, img, 
dl, dt, dd, ol, ul, li, fieldset, form,
label, legend {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
/*	background: transparent; Big problem with IE background images on elements */
}
table, caption, tbody, tfoot, thead, tr, th, td {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	background: transparent;
}

input,select,textarea {
	font-size: 100%;
	box-sizing: border-box;
}

/* remember to define focus styles! */
:focus { outline: 0; }
/* tables still need 'cellspacing="0"' in the markup */
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* HTML5 display definitions */

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section { display: block; }
audio, canvas, video { display: inline-block; *display: inline; *zoom: 1; }
audio:not([controls]) { display: none; }
[hidden] { display: none; }


/* CLEARFIX */

* html .clearfix {
	height: 1%; /* IE5-6 */
}
.clearfix {
	display: inline-block; /* IE7xhtml*/
}
html[xmlns] .clearfix { /* O */
	display: block;  
}
.clearfix:after { /* FF, O, etc. */
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}