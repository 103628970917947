div.tabs {
  font-size: 16px;
  overflow: auto;
  width: 100%;
  ul { 
    border: 0;
    margin: 0;
    padding: 0;
  }
  li {
    display: block;
    float: left;
    list-style: none outside none;
    margin: 0 0 1em 0;
    a {
      background-color: white;
      border: 1px solid red;
      border-left: 0;
      color: red;
      display: block;
      font-weight: bold;
      height: 2em;
      line-height: 2.125em;
      margin: 0;
      padding: 0 1em;
      text-align: center;
      text-decoration: none;
      text-transform: lowercase;
      white-space: nowrap;
    }
    a.active, a.active:hover {
      background-color: red;
      color: white;
      cursor: default;
    }
    a:hover {
      background-color: pink;
      border-color: red;
      color: white;
    }
  }
  li.active a { border: 1px solid red; }
  li:first-child a {
    border-radius: 1em 0 0 1em;
//    border-left: 1px solid red;
  }
  li:last-child a {
    border-radius: 0 1em 1em 0;
  }
}