/* RESET */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, address, cite, code, em, img,
dl, dt, dd, ol, ul, li, fieldset, form,
label, legend {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  /*	background: transparent; Big problem with IE background images on elements */ }

table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent; }

input, select, textarea {
  font-size: 100%;
  box-sizing: border-box; }

/* remember to define focus styles! */
:focus {
  outline: 0; }

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* HTML5 display definitions */
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none; }

[hidden] {
  display: none; }

/* CLEARFIX */
* html .clearfix {
  height: 1%;
  /* IE5-6 */ }

.clearfix {
  display: inline-block;
  /* IE7xhtml*/ }

html[xmlns] .clearfix {
  /* O */
  display: block; }

.clearfix:after {
  /* FF, O, etc. */
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

body {
  font-size: 62.5%; }

/* improve admin menu drop down usability */
#admin-menu .dropdown a, #admin-menu .dropdown li > span {
  padding: 8px; }

body.admin-menu {
  margin-top: 28px !important; }

@font-face {
  font-family: 'Ebisu';
  src: url("../fonts/270E0A_8_0.eot");
  src: url("../fonts/270E0A_8_0.eot?#iefix") format("embedded-opentype"), url("../fonts/270E0A_8_0.woff") format("woff"), url("../fonts/270E0A_8_0.ttf") format("truetype");
  font-weight: bold; }

@font-face {
  font-family: 'Ebisu';
  src: url("../fonts/270E0A_6_0.eot");
  src: url("../fonts/270E0A_6_0.eot?#iefix") format("embedded-opentype"), url("../fonts/270E0A_6_0.woff") format("woff"), url("../fonts/270E0A_6_0.ttf") format("truetype");
  font-weight: normal; }

@font-face {
  font-family: 'District';
  src: url("../fonts/DistLt__.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/DistLt__.eot?#iefix") format("embedded-opentype"), url("../fonts/DistLt__.woff") format("woff"), url("../fonts/DistLt__.ttf") format("truetype"), url("../fonts/DistLt__.svg#4f1623af231073d2b145ac13f7a83fa3") format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 200; }

@font-face {
  font-family: 'District';
  src: url("../fonts/DistLtIt.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/DistLtIt.eot?#iefix") format("embedded-opentype"), url("../fonts/DistLtIt.woff") format("woff"), url("../fonts/DistLtIt.ttf") format("truetype"), url("../fonts/DistLtIt.svg#c012145674e2a9c6837e8d594f415333") format("svg");
  /* Legacy iOS */
  font-style: italic;
  font-weight: 200; }

@font-face {
  font-family: 'District';
  src: url("../fonts/DistDm__.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/DistDm__.eot?#iefix") format("embedded-opentype"), url("../fonts/DistDm__.woff") format("woff"), url("../fonts/DistDm__.ttf") format("truetype"), url("../fonts/DistDm__.svg#c012145674e2a9c6837e8d594f415333") format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: 'District';
  src: url("../fonts/DistDmIt.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/DistDmIt.eot?#iefix") format("embedded-opentype"), url("../fonts/DistDmIt.woff") format("woff"), url("../fonts/DistDmIt.ttf") format("truetype"), url("../fonts/DistDmIt.svg#c012145674e2a9c6837e8d594f415333") format("svg");
  /* Legacy iOS */
  font-style: italic;
  font-weight: 700; }

body {
  color: #333;
  font-size: 14px;
  font-family: 'Ebisu', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 200; }

h2 {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 3px;
  margin: 10px 0 25px;
  text-transform: uppercase; }

h4 {
  font-weight: normal;
  letter-spacing: 2px;
  text-transform: uppercase; }

.content p,
.content ul,
.content ol {
  line-height: 1.4;
  margin: 10px 0; }

a {
  color: #333;
  text-decoration: none; }

p a:hover {
  border-bottom: 1px solid #84C3F0;
  padding-bottom: 2px;
  margin-bottom: -3px; }

nav a:hover {
  display: inline-block;
  outline: 1px solid #84C3F0;
  outline-offset: 5px; }
  nav a:hover * {
    opacity: 0.25; }

/* LAYOUT
/* ---------------------------------------------------- */
.section {
  margin: 0 auto; }

header.section {
  margin: 100px auto 75px;
  position: relative; }

nav.top {
  position: absolute;
  top: -80px;
  right: 0; }

.logo {
  display: inline-block;
  float: left;
  height: 65px;
  margin-right: 50px;
  width: 280px; }
  .logo a, .logo img {
    display: block;
    height: auto;
    width: 100%; }

#content {
  margin-bottom: 100px; }

/* PROJECTS VIEW */
nav.specialties,
nav.secondary {
  display: inline-block; }
  nav.specialties ul,
  nav.secondary ul {
    display: table;
    height: 65px; }
  nav.specialties li,
  nav.secondary li {
    list-style: none outside none;
    display: table-cell;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 3px;
    padding-right: 40px;
    text-transform: uppercase;
    vertical-align: bottom; }
    nav.specialties li a,
    nav.secondary li a {
      display: inline-block;
      padding-top: 5px;
      white-space: nowrap; }
  nav.specialties li.active a,
  nav.specialties li.active-trail a,
  nav.secondary li.active a,
  nav.secondary li.active-trail a {
    display: inline-block;
    outline: 1px solid #84C3F0;
    outline-offset: 5px; }

nav.tags {
  margin: 50px 0 -25px;
  padding: 15px 0 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase; }
  nav.tags h4 {
    display: inline-block;
    font-weight: normal;
    margin-right: 10px; }
  nav.tags ul, nav.tags li {
    display: inline-block;
    list-style: none outside none; }
  nav.tags li {
    margin: 0 15px 15px 0; }
  nav.tags a {
    display: inline-block;
    padding-top: 3px; }
  nav.tags li.active a {
    display: inline-block;
    outline: 1px solid #84C3F0;
    outline-offset: 5px; }

.view-projects {
  background: transparent url(../images/loading.gif) 50% 50%/18px 18px no-repeat;
  min-height: 18px; }

.view-projects.ms-ready {
  background-image: none; }

.ms-container {
  display: none; }

.view-projects .item-list {
  display: none; }

.ms-item {
  margin-bottom: 50px;
  width: 280px;
  /*  a:hover { 
    display: block;
    outline: none;
  }*/
  /* Throbber */ }
  .ms-item .caption {
    margin: 10px 0; }
    .ms-item .caption * {
      font-size: 10px;
      font-weight: bold;
      line-height: 1.25;
      margin: 0 0 5px 0;
      text-transform: uppercase;
      letter-spacing: 1px; }
  .ms-item img {
    display: block;
    width: 100%; }
  .ms-item .ajax-progress {
    background: white;
    display: block;
    height: 100%;
    opacity: 0.9;
    outline: 1px solid white;
    position: absolute;
    top: 0;
    width: 100%; }
  .ms-item .ajax-progress .throbber {
    background: transparent url(../images/loading.gif) 50% 50%/18px 18px no-repeat;
    float: none;
    height: 100%;
    margin: 0 auto;
    width: 100%; }

.node-link:hover {
  display: inline-block;
  outline: 1px solid #84C3F0;
  outline-offset: 5px; }
  .node-link:hover .thumbnail * {
    opacity: 0.25; }

.pager-load-more {
  margin: 12px 0 24px;
  /* Throbber */ }
  .pager-load-more a {
    color: #333;
    display: inline-block;
    outline: 1px solid #84C3F0;
    outline-offset: 5px;
    outline-color: #333; }
  .pager-load-more a:hover {
    color: #84C3F0;
    outline-color: #84C3F0; }
  .pager-load-more .ajax-progress {
    display: inline-block;
    vertical-align: middle; }
  .pager-load-more .ajax-progress .throbber {
    background: transparent url(../images/loading.gif) 0 0/18px 18px no-repeat;
    float: left;
    /* LTR */
    height: 18px;
    margin-left: 10px;
    width: 18px; }

.loading a {
  display: none; }

/* PROJECTS POPUP */
.mfp-bg {
  background: white;
  opacity: 1; }

.mfp-content,
.mfp-content > div,
.mfp-content article,
.mfp-content .photo-credits {
  box-sizing: border-box;
  height: 100%; }

.mfp-container {
  box-sizing: border-box;
  padding: 50px;
  position: fixed; }
  .mfp-container .mfp-close,
  .mfp-container .mfp-info {
    -webkit-appearance: none;
    background: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
    display: block;
    font-style: normal;
    font-size: 20px;
    font-weight: normal;
    font-family: 'District', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    height: 20px;
    line-height: 20px;
    opacity: 1;
    filter: alpha(opacity=100);
    overflow: visible;
    padding: 0 0 10px 5px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    padding: 0;
    width: 20px;
    z-index: 1046;
    outline: 1px solid #333;
    outline-offset: 0; }
  .mfp-container .mfp-info {
    font-size: 16px; }

.mfp-close {
  margin: -40px -40px 0 0; }

.mfp-info {
  margin: -6px -40px 0 0; }

.mfp-close:hover,
.mfp-close:focus,
.mfp-info:hover,
.mfp-info:focus {
  color: #84C3F0;
  outline-color: #84C3F0; }

.photo-credits {
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: -9999px;
  width: 100%; }
  .photo-credits .inner {
    width: 400px;
    margin: 50px auto; }

.photo-container {
  background: transparent url(../images/loading.gif) 50% 50%/18px 18px no-repeat;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  width: 100%; }
  .photo-container > * {
    height: auto;
    max-width: 100%;
    width: auto; }
  .photo-container img {
    display: block;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%; }

.mfp-s-ready .photo-container {
  background-image: none; }

.node-project .photo-container > * {
  display: none;
  height: 100%; }

.node-project .photo-container > *:first-child {
  display: block; }

.twentytwenty-container {
  margin: 0 auto; }

.twentytwenty-handle {
  margin-left: -3px; }

.c-arrow {
  cursor: e-resize !important; }

.c-pointer {
  cursor: pointer !important; }

div.tabs {
  font-size: 16px;
  overflow: auto;
  width: 100%; }
  div.tabs ul {
    border: 0;
    margin: 0;
    padding: 0; }
  div.tabs li {
    display: block;
    float: left;
    list-style: none outside none;
    margin: 0 0 1em 0; }
    div.tabs li a {
      background-color: white;
      border: 1px solid red;
      border-left: 0;
      color: red;
      display: block;
      font-weight: bold;
      height: 2em;
      line-height: 2.125em;
      margin: 0;
      padding: 0 1em;
      text-align: center;
      text-decoration: none;
      text-transform: lowercase;
      white-space: nowrap; }
    div.tabs li a.active, div.tabs li a.active:hover {
      background-color: red;
      color: white;
      cursor: default; }
    div.tabs li a:hover {
      background-color: pink;
      border-color: red;
      color: white; }
  div.tabs li.active a {
    border: 1px solid red; }
  div.tabs li:first-child a {
    border-radius: 1em 0 0 1em; }
  div.tabs li:last-child a {
    border-radius: 0 1em 1em 0; }

/* extra-small screens -------------------------------------- */
@media only screen and (max-width: 609px) {
  .section {
    width: 280px; }
  header.section {
    margin: 50px auto; }
  .logo {
    float: none;
    margin: 0 0 50px 0; }
  .menu.specialties,
  .menu.secondary {
    display: block; }
    .menu.specialties ul,
    .menu.secondary ul {
      display: block;
      height: auto; }
    .menu.specialties li,
    .menu.secondary li {
      display: block;
      margin-bottom: 10px;
      font-size: 13px; } }

@media only screen and (min-width: 710px) {
  .section {
    width: 610px; } }

@media only screen and (min-width: 1040px) {
  .section {
    width: 940px; } }

@media only screen and (min-width: 1370px) {
  .section {
    width: 1270px; } }
