@import "base";
@import "typography";
@import "forms";

/* LAYOUT
/* ---------------------------------------------------- */

.section { 
  margin: 0 auto;
}
header.section {
  margin: 100px auto 75px;
  position: relative;
}
nav.top {
  position: absolute;
  top: -80px;
  right: 0;
}
.logo { 
  display: inline-block;
  float: left;
  height: 65px;
  margin-right: 50px;
  width: 280px;
  a, img { 
    display: block;
    height: auto;
    width: 100%;
  }
}
#content {
  margin-bottom: 100px;
}

/* PROJECTS VIEW */

nav.specialties,
nav.secondary {
  display: inline-block;
  ul { 
    display: table;
    height: 65px;
  }
  li {
    list-style: none outside none;
    display: table-cell;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 3px;
    padding-right: 40px;
    text-transform: uppercase;
    vertical-align: bottom;
    a { 
      display: inline-block;
      padding-top: 5px;
      white-space: nowrap;
    }
  }
  li.active a,
  li.active-trail a { @include outline; }
}
nav.tags {
  margin: 50px 0 -25px;
  padding: 15px 0 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  h4 { 
    display: inline-block; 
    font-weight: normal;
    margin-right: 10px; 
  }
  ul, li {
    display: inline-block;
    list-style: none outside none;
  }
  li { margin: 0 15px 15px 0; }
  a { 
    display: inline-block;
    padding-top: 3px; 
  }
  li.active a { @include outline; }
}
.view-projects { 
  background: transparent url(../images/loading.gif) 50% 50% / 18px 18px no-repeat;
  min-height: 18px;
}
.view-projects.ms-ready { background-image: none; }
.ms-container { display: none; } // <- show when masonry is ready
.view-projects .item-list { display: none; }
.ms-item { 
  margin-bottom: 50px; 
  width: 280px;
  .caption { 
    margin: 10px 0;
    * {
      font-size: 10px;
      font-weight: bold;
      line-height: 1.25;
      margin: 0 0 5px 0;
      text-transform: uppercase;
      letter-spacing: 1px;
     }
  }
/*  a:hover { 
    display: block;
    outline: none;
  }*/  
  img {
    display: block;
    width: 100%; 
  }
  /* Throbber */
  .ajax-progress {
    background: white;
    display: block;
    height: 100%;
    opacity: 0.9;
    outline: 1px solid white;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .ajax-progress .throbber {
    background: transparent url(../images/loading.gif) 50% 50% / 18px 18px no-repeat;
    float: none;
    height: 100%;
    margin: 0 auto;
    width: 100%;
  }

}
.node-link:hover {
  @include outline;
  .thumbnail {
    * { opacity: 0.25; }
  }
}
.pager-load-more { 
  margin: 12px 0 24px;
  a {
    color: $black;
    @include outline;
    outline-color: $black;
  }
  a:hover {
    color: $highlight;
    outline-color: $highlight; 
  }
  /* Throbber */
  .ajax-progress {
    display: inline-block;
    vertical-align: middle;
  }
  .ajax-progress .throbber {
    background: transparent url(../images/loading.gif) 0 0 / 18px 18px no-repeat;
    float: left; /* LTR */
    height: 18px;
    margin-left: 10px;
    width: 18px;
  }
}
.loading a { display: none; }

/* PROJECTS POPUP */

.mfp-bg {
  background: white;
  opacity: 1;
}

.mfp-content,
.mfp-content > div,
.mfp-content article,
.mfp-content .photo-credits { 
  box-sizing: border-box;
  height: 100%; 
}

.mfp-container {
  box-sizing: border-box;
  padding: 50px;
  position: fixed; // necessary for IOS
  .mfp-close,
  .mfp-info {
    -webkit-appearance: none;
    background: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none; 
    cursor: pointer;
    display: block;
    font-style: normal;
    font-size: 20px;
    font-weight: normal;
    font-family: 'District', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    height: 20px;
    line-height: 20px;
    opacity: 1;
    filter: alpha(opacity=100);
    overflow: visible;
    padding: 0 0 10px 5px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    padding: 0;
    width: 20px;
    z-index: 1046;
    outline: 1px solid $black;
    outline-offset: 0;
  }
  .mfp-info { font-size: 16px; }
}
.mfp-close { margin: -40px -40px 0 0; }
.mfp-info {  margin: -6px -40px 0 0; }
.mfp-close:hover, 
.mfp-close:focus,
.mfp-info:hover, 
.mfp-info:focus {
  color: $highlight;
  outline-color: $highlight;
}

.photo-credits {
  background-color: rgba(255,255,255,0.9);
  position: absolute;
//  padding: 50px;
  top: -9999px;
  width: 100%;
  .inner {
    width: 400px;
    margin: 50px auto;
  }
}

.photo-container {
  background: transparent url(../images/loading.gif) 50% 50% / 18px 18px no-repeat;
  box-sizing: border-box;
  height: 100%; 
  overflow: hidden;
  width: 100%; 
  > * { 
    height: auto;
    max-width: 100%;
    width: auto;
  }
  img {
    display: block;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
  }
}
.mfp-s-ready .photo-container { background-image: none; }
.node-project .photo-container > * { 
  display: none; 
  height: 100%; 
}
.node-project .photo-container > *:first-child { display: block; }
.twentytwenty-container { margin: 0 auto; }
.twentytwenty-handle { margin-left: -3px; }

.c-arrow { cursor: e-resize !important; }
.c-pointer { cursor: pointer !important; }


@import "tabs";
@import "responsive";