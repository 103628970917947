@font-face {font-family: 'Ebisu';src: url('../fonts/270E0A_8_0.eot');src: url('../fonts/270E0A_8_0.eot?#iefix') format('embedded-opentype'),url('../fonts/270E0A_8_0.woff') format('woff'),url('../fonts/270E0A_8_0.ttf') format('truetype'); font-weight: bold; }
@font-face {font-family: 'Ebisu';src: url('../fonts/270E0A_6_0.eot');src: url('../fonts/270E0A_6_0.eot?#iefix') format('embedded-opentype'),url('../fonts/270E0A_6_0.woff') format('woff'),url('../fonts/270E0A_6_0.ttf') format('truetype'); font-weight: normal }
@font-face {
  font-family: 'District';
  src: url('../fonts/DistLt__.eot'); /* IE9 Compat Modes */
  src: url('../fonts/DistLt__.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/DistLt__.woff') format('woff'), /* Modern Browsers */
       url('../fonts/DistLt__.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/DistLt__.svg#4f1623af231073d2b145ac13f7a83fa3') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: 'District';
  src: url('../fonts/DistLtIt.eot'); /* IE9 Compat Modes */
  src: url('../fonts/DistLtIt.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/DistLtIt.woff') format('woff'), /* Modern Browsers */
       url('../fonts/DistLtIt.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/DistLtIt.svg#c012145674e2a9c6837e8d594f415333') format('svg'); /* Legacy iOS */
  font-style: italic;
  font-weight: 200;
}
@font-face {
  font-family: 'District';
  src: url('../fonts/DistDm__.eot'); /* IE9 Compat Modes */
  src: url('../fonts/DistDm__.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/DistDm__.woff') format('woff'), /* Modern Browsers */
       url('../fonts/DistDm__.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/DistDm__.svg#c012145674e2a9c6837e8d594f415333') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'District';
  src: url('../fonts/DistDmIt.eot'); /* IE9 Compat Modes */
  src: url('../fonts/DistDmIt.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/DistDmIt.woff') format('woff'), /* Modern Browsers */
       url('../fonts/DistDmIt.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/DistDmIt.svg#c012145674e2a9c6837e8d594f415333') format('svg'); /* Legacy iOS */
  font-style: italic;
  font-weight: 700;
}

body {
  color: $black;
  font-size: 14px;
  font-family: 'Ebisu', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 200;
}
h2 {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 3px;
  margin: 10px 0 25px;
  text-transform: uppercase;
}
h4 {
  font-weight: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.content p,
.content ul,
.content ol { 
  line-height: 1.4;
  margin: 10px 0;
}
a {
  color: $black;
  text-decoration: none;
}
p a:hover {
  border-bottom: 1px solid $highlight;
  padding-bottom: 2px;
  margin-bottom: -3px;
}
nav a:hover {
  @include outline;
  * { opacity: 0.25; }
}
